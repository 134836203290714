import * as React from "react"

const Header = () => {
  return (
    <div className="parallax1 text-center">
      <div className="intro">
        <div className="animate d-flex flex-row justify-content-center">
          <div className="alpha">
            <p>A</p>
          </div>
          <div className="alpha">
            <p>u</p>
          </div>
          <div className="alpha">
            <p>r</p>
          </div>
          <div className="alpha">
            <p>e</p>
          </div>
          <div className="alpha">
            <p>l</p>
          </div>
          <div className="alpha">
            <p>l</p>
          </div>
          <div className="alpha">
            <p>y</p>
          </div>
          <div className="alpha">
            <p>a</p>
          </div>
        </div>
        <div className="title">
          <div className="d-inline-block">
            <p>
              <span>Software</span>
              <span className="span-sep">&nbsp;</span>
              <span>Developer</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
