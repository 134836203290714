import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const Project = () => {
  return (
    <div className="parallax2" id="projectDiv">
      <div>
        {/* title */}
        <div className="mb-5" id="project">
          <p className="about-title" style={{ color: "#bbc5ba" }}>
            <b>MY PROJECTS</b>
          </p>
          <svg width="70" height="8">
            <rect
              width="70"
              height="8"
              style={{ fill: "#bbc5ba", strokeWidth: "3" }}
            />
          </svg>
        </div>

        {/* content */}
        <div className="container pb-5">
          <div className="row mb-5">
            <div className="col py-3 px-3 py-md-5 text-center">
              <div className="d-flex align-items-center justify-content-center">
                <StaticImage
                  src="../../images/icon/code2.png"
                  placeholder="blurred"
                  width={220}
                  height={220}
                  layout="fixed"
                  alt="terminal"
                  className="img-1"
                />
              </div>
              <p>Programming</p>
              <button
                className="btn l-btn"
                aria-current="page"
                onClick={() => {
                  navigate("/projects")
                }}
              >
                See Projects!
              </button>
            </div>

            <div className="col py-3 px-3 py-md-5 text-center">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "220px" }}
              >
                <StaticImage
                  src="../../images/icon/wave2.png"
                  placeholder="blurred"
                  alt="audio wave"
                  width={220}
                  height={153}
                  layout="fixed"
                  className="img-2"
                />
              </div>
              <p>Music</p>

              <button
                className="btn r-btn"
                aria-current="page"
                onClick={() => {
                  navigate("/music")
                }}
              >
                See Projects!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project
