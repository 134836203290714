import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/home/header"
import About from "../components/home/about"
import Project from "../components/home/project"
import Skill from "../components/home/skill"
import Contact from "../components/home/contact"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Header />
    <About />
    <Project />
    <Skill />
    <Contact />
  </Layout>
)

export default IndexPage
