import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "index.md" }) {
        childrenMarkdownRemark {
          frontmatter {
            email
            website
            github
            location
          }
        }
      }
    }
  `)

  const {
    email,
    website,
    github,
    location,
  } = data.file.childrenMarkdownRemark[0].frontmatter

  return (
    <div className="row contact-info m-0 p-0">
      <div className="col-6 pb-5" id="contact">
        <div className="content">
          {/* title */}
          <div>
            <div className="mb-4 mt-5 pt-4">
              <p className="about-title">
                <b>QUICK ID</b>
              </p>
              <svg width="70" height="8">
                <rect
                  width="70"
                  height="8"
                  style={{ fill: "#bbc5ba", strokeWidth: "3" }}
                />
              </svg>
            </div>
          </div>

          {/* content */}
          <div className="info pb-5">
            <div className="row">
              <div className="col-2">Email</div>
              <div className="col">{email}</div>
            </div>
            <div className="row">
              <div className="col-2">Website</div>
              <div className="col">{website}</div>
            </div>
            <div className="row">
              <div className="col-2">Github</div>
              <div className="col">{github}</div>
            </div>
            <div className="row">
              <div className="col-2">Location</div>
              <div className="col">{location}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-6 pb-5 lightParallax">
        <div className="content" id="form">
          {/* title */}
          <div>
            <div className="mb-4 mt-5 pt-4">
              <p className="about-title">
                <b style={{ color: "#1d261d" }}>CONTACT ME</b>
              </p>
              <svg width="70" height="8">
                <rect
                  width="70"
                  height="8"
                  style={{ fill: "#1d261d", strokeWidth: "3" }}
                />
              </svg>
            </div>
          </div>

          {/* content */}
          <div className="info">
            <form
              action="POST"
              data-netlify="true"
              onInvalid={() => alert("You must fill out the form!")}
            >
              <div className="col-5 me-auto">
                <input
                  type="text"
                  id="name"
                  name="Name"
                  placeholder="Name"
                  required
                />
              </div>

              <div className="col-6">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                />
              </div>

              <input
                type="text"
                id="subject"
                name="subject"
                placeholder="Subject"
                required
              />

              <textarea
                id="message"
                name="message"
                placeholder="Message"
                required
              ></textarea>

              <div className="submit-button mx-auto">
                <button id="submit" type="submit" href="/">
                  <b>Submit</b>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
