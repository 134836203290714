import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Skill = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "index.md" }) {
        childrenMarkdownRemark {
          frontmatter {
            skills {
              name
              items
              icon {
                childImageSharp {
                  gatsbyImageData(
                    width: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const { skills } = data.file.childrenMarkdownRemark[0].frontmatter

  return (
    <div className="parallax3">
      {/* title */}
      <div>
        <div className="mb-5" id="skill">
          <p className="about-title" style={{ color: "#bbc5ba" }}>
            <b>SKILLS</b>
          </p>
          <svg width="70" height="8">
            <rect
              width="70"
              height="8"
              style={{ fill: "#bbc5ba", strokeWidth: "3" }}
            />
          </svg>
        </div>
      </div>

      {/* content */}
      <div className="container py-5">
        <div className="row mb-3" style={{ columnGap: "1.9rem" }}>
          {skills.map(skill => (
            <div className="col flip-card my-4" key={skill.name}>
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <h1 className="fw-bold mb-3">
                    {skill.name.split(" ").map(s => (
                      <span key={s}>
                        {s}
                        <br />
                      </span>
                    ))}
                  </h1>
                  <div className="tools-icon-div">
                    <GatsbyImage
                      image={getImage(skill.icon)}
                      alt={skill.name}
                    />
                  </div>
                </div>
                <div className="flip-card-back">
                  <h1 className="fw-bold">
                    {skill.name === "Programming Language"
                      ? skill.name.split(" ").map(s => (
                          <span key={s}>
                            {s}
                            <br />
                          </span>
                        ))
                      : skill.name}
                  </h1>
                  <hr />
                  {skill.items.map(item => (
                    <p key={item}>{item}</p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Skill
