import React, { useEffect } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "index.md" }) {
        childrenMarkdownRemark {
          frontmatter {
            about
            education {
              description
              school
              year
            }
            latest_projects {
              name
              tech
              link
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            social_media {
              href
              name
              image2 {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    about,
    education,
    latest_projects,
    social_media,
  } = data.file.childrenMarkdownRemark[0].frontmatter

  var slideIndex = 0
  var slides, dots, timeout

  const showSlides = () => {
    slides = document.getElementsByClassName("mySlides")
    dots = document.getElementsByClassName("dot")

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none"
    }

    slideIndex++

    if (slideIndex > slides.length) {
      slideIndex = 1
    }

    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "")
    }

    slides[slideIndex - 1].style.display = "block"
    dots[slideIndex - 1].className += " active"
    timeout = setTimeout(showSlides, 14000)
  }

  useEffect(() => {
    showSlides()
    return () => {
      clearTimeout(timeout)
    }
  })

  const plusSlides = position => {
    slideIndex += position
    if (slideIndex > slides.length) {
      slideIndex = 1
    } else if (slideIndex < 1) {
      slideIndex = slides.length
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none"
    }

    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "")
    }

    slides[slideIndex - 1].style.display = "block"
    dots[slideIndex - 1].className += " active"
  }

  const currentSlide = index => {
    if (index > slides.length) {
      index = 1
    } else if (index < 1) {
      index = slides.length
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none"
    }

    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "")
    }

    slides[index - 1].style.display = "block"
    dots[index - 1].className += " active"
  }

  return (
    <div>
      <div className="avatar" id="about">
        <div className="avatar-div">
          <div className="avatar-img">
            <StaticImage
              src="../../images/profile/chip.png"
              alt="Chip"
              placeholder="blurred"
              width={145}
              height={145}
            />
          </div>
        </div>
      </div>

      <div className="dark_green_bg">
        <div className="position-relative m-auto">
          <div className="d-flex flex-row flex-nowrap justify-content-center">
            {/* Next and previous buttons */}
            <div className="prev-btn-div">
              <button
                className="prev"
                onClick={() => plusSlides(-1)}
                onKeyDown={() => plusSlides(1)}
              >
                &#10094;
              </button>
            </div>

            {/* ABOUT ME */}
            <div className="mySlides fade-effect" id="aboutme">
              <div className="main-content">
                {/* title */}
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                  {/* left title */}
                  <div>
                    <p className="about-title">
                      <b>ABOUT ME</b>
                    </p>
                    <svg width="70" height="8">
                      <rect
                        width="70"
                        height="8"
                        style={{ fill: "#bbc5ba", strokeWidth: "3" }}
                      />
                    </svg>
                  </div>

                  {/* right title */}
                  <div className="d-flex flex-row flex-nowrap justify-content-end">
                    {social_media.map(media => (
                      <a href={media.href} key={media.name}>
                        <div className="img-container d-flex align-items-center justify-content-center">
                          <GatsbyImage
                            image={getImage(media.image2)}
                            alt={media.name}
                            className={
                              media.name === "youtube"
                                ? "flaticon2"
                                : "flaticon1"
                            }
                          />
                        </div>
                      </a>
                    ))}
                  </div>
                </div>

                {/* content */}
                <div className="content-container d-flex flex-row align-items-start justify-content-between">
                  <div className="l-about">
                    <p className="aboutme-text">{about[0]}</p>
                  </div>
                  <div className="mx-3"></div>
                  <div className="r-about">
                    <p className="aboutme-text">{about[1]}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* EDUCATION */}
            <div className="mySlides fade-effect" id="education">
              <div className="main-content">
                {/* title */}
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                  {/* left title */}
                  <div>
                    <p className="about-title">
                      <b>EDUCATION</b>
                    </p>
                    <svg width="70" height="8">
                      <rect
                        width="70"
                        height="8"
                        style={{ fill: "#bbc5ba", strokeWidth: "3" }}
                      />
                    </svg>
                  </div>

                  {/* right title */}
                  <div className="d-flex flex-row flex-nowrap justify-content-end">
                    {social_media.map(media => (
                      <a href={media.href} key={media.name}>
                        <div className="img-container d-flex align-items-center justify-content-center">
                          <GatsbyImage
                            image={getImage(media.image2)}
                            alt={media.name}
                            className={
                              media.name === "youtube"
                                ? "flaticon2"
                                : "flaticon1"
                            }
                          />
                        </div>
                      </a>
                    ))}
                  </div>
                </div>

                {/* content */}
                <div className="content-container row g-4 row-cols-1 row-cols-lg-3">
                  {education.map(edu => (
                    <div key={edu.school} className="feature col m-0">
                      <h5 className="m-0 fw-bold mb-1">{edu.school}</h5>
                      <h5 className="m-0 fw-bold">{edu.year}</h5>
                      <p className="edu-text mt-3">{edu.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* LATEST PROJECTS */}
            <div className="mySlides fade-effect" id="latestprojects">
              <div className="main-content">
                {/* title */}
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-between">
                  {/* left title */}
                  <div>
                    <p className="about-title">
                      <b>LATEST PROJECT</b>
                    </p>
                    <svg width="70" height="8">
                      <rect
                        width="70"
                        height="8"
                        style={{ fill: "#bbc5ba", strokeWidth: "3" }}
                      />
                    </svg>
                  </div>

                  {/* right title */}
                  <div className="d-flex flex-row flex-nowrap justify-content-end">
                    {social_media.map(media => (
                      <a href={media.href} key={media.name}>
                        <div className="img-container d-flex align-items-center justify-content-center">
                          <GatsbyImage
                            image={getImage(media.image2)}
                            alt={media.name}
                            className={
                              media.name === "youtube"
                                ? "flaticon2"
                                : "flaticon1"
                            }
                          />
                        </div>
                      </a>
                    ))}
                  </div>
                </div>

                {/* content */}
                <div className="content-container d-flex flex-row align-items-start justify-content-between mb-3">
                  <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4">
                    {latest_projects.map(proj => (
                      <div
                        role="button"
                        tabIndex={0}
                        className="col"
                        key={proj.name}
                        onClick={() => navigate(proj.link)}
                        onKeyDown={() => navigate(proj.link)}
                      >
                        <div className="card card-cover h-100 overflow-hidden rounded-5 shadow-lg position-relative">
                          <div className="h-100">
                            <GatsbyImage
                              image={getImage(proj.image)}
                              alt={proj.name}
                              className="h-100 w-100 thumbnail-img position-absolute"
                            />
                            <div className="thumbnail-layer h-100 w-100 position-absolute"></div>
                            <div className="inner-card d-flex flex-column align-items-end h-100 text-shadow-1 p-5 pb-3 position-relative">
                              <h2 className="mt-1 mb-5 lh-1 fw-bold">
                                {proj.name}
                              </h2>
                              <ul className="d-flex justify-content-end list-unstyled mt-auto">
                                <li>
                                  <small>
                                    {proj.tech.map(t => (
                                      <span key={proj.name + t}>{t}&nbsp;</span>
                                    ))}
                                  </small>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Next and previous buttons */}
            <div className="next-btn-div">
              <button
                className="next"
                onClick={() => plusSlides(1)}
                onKeyDown={() => plusSlides(1)}
              >
                &#10095;
              </button>
            </div>
          </div>

          <div className="dots text-center">
            <span
              aria-hidden="true"
              className="dot dot1"
              onClick={() => currentSlide(1)}
              onKeyDown={() => currentSlide(1)}
            ></span>
            <span
              aria-hidden="true"
              className="dot dot2"
              onClick={() => currentSlide(2)}
              onKeyDown={() => currentSlide(1)}
            ></span>
            <span
              aria-hidden="true"
              className="dot dot3"
              onClick={() => currentSlide(3)}
              onKeyDown={() => currentSlide(1)}
            ></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
